import React, { useState, useEffect, useLayoutEffect, useMemo, useCallback } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import SectionContentFeaturedList from '../../components/section-content-featured-list';
import CarouselStrip from '../../components/carousel-strip/carousel-strip';
import AccordionDataListNew from '../../components/accordion-data-list-new';
import SectionContainerLayout from '../../components/section-container-layout/section-container-layout';
import CardImageTitleDescription from '../../components/card-image-title-description/card-image-title-description';
import pageData from '../../data/summits/2017';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import StaticImageTitleDescription from '../../components/static-image-title-description';
import ImageContainer from '../../components/image-container';
import FeaturedCardStatistic from '../../components/featured-card-statistic';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import { navigate } from 'gatsby';
import CustomGrid from '../../components/custom-grid/custom-grid';
import CustomGridItem from '../../components/custom-grid/custom-grid-item';
// import CustomLink from '../../../components/custom-link';
import axios from 'axios';
import moment from 'moment';
import SectionDescription from '../../components/section-description/section-description';
import AnimatedCard from '../../components/card-image-title-description/animated-card';
import ScrollbarAlwaysOn from '../../components/scrollbar/scrollbar-always-on';
import { useCustomGridBreakpoints } from '../../components/custom-grid/useCustomGridBreakpoints';

const Page_2017 = ({ pageContext: { _partner2017 } }) => {
  const currentYear = 2017;
  const agendaMonth = 'March';
  const [agendaDays, setAgendaDays] = useState([]);
  const [agendaLoaded, setAgendaLoaded] = useState(false);
  const [agendaIndex, setAgendaIndex] = useState();
  const [speakers, setSpeakers] = useState([]);
  const [carousels, setCarousels] = useState([]);
  const [albums, setAlbums] = useState([]);
  const [isCarouselLoaded, setIsCarouselLoaded] = useState(false);
  const [isShowPartners, setIsShowPartners] = useState(true);
  const [news, setNews] = useState([]);
  const [isSpeakersShownAll, setIsSpeakersShownAll] = useState(false);
  const { isDownXs, isDownSm, isDownMd, isDownLg } = useCustomGridBreakpoints();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let _partnersCount = 0;

  const resetPageData = useCallback(async () => {
    const [newsResponse] = await Promise.all([axios.get(`${process.env.GATSBY_CMS_API_URL}/news`)]);
    setNews(getPageYearData(newsResponse.data));
  }, []);

  const getPageYearData = (items) => {
    return items.filter((item) => Number(item.year) === YEAR);
  };

  const newsData = useMemo(() => {
    return news.map((news) => ({ ...news, file: [news.file] }));
  }, [news]);

  useLayoutEffect(() => {
    try {
      let _speakersByYearArray = [];
      let _carouselsByYearArray = [];
      let _albumsByYearArray = [];

      Promise.all([
        axios.get(`${process.env.GATSBY_CMS_API_URL}/speaker`, {
          proxy: {
            host: process.env.GATSBY_CMS_API_URL,
            port: 5000,
          },
        }),
        axios.get(`${process.env.GATSBY_CMS_API_URL}/carousel`, {
          proxy: {
            host: process.env.GATSBY_CMS_API_URL,
            port: 5000,
          },
        }),
        axios.get(`${process.env.GATSBY_CMS_API_URL}/album`, {
          proxy: {
            host: process.env.GATSBY_CMS_API_URL,
            port: 5000,
          },
        }),
      ])
        .then((_responses) => {
          if (_responses[0].status === 200) {
            const { data } = _responses[0];
            let count = 0;
            data.forEach((_speaker) => {
              if (_speaker.year === currentYear && count < 15) {
                count++;
                _speakersByYearArray.push(_speaker);
              }
            });

            setSpeakers(_speakersByYearArray.sort((a, b) => a.order - b.order));
          }
          if (_responses[1].status === 200) {
            const { data } = _responses[1];

            data.forEach((_carousel) => {
              if (_carousel.year === currentYear) {
                _carouselsByYearArray.push(_carousel);
              }
            });

            setCarousels(_carouselsByYearArray);
            setIsCarouselLoaded(true);
          }
          if (_responses[2].status === 200) {
            const { data } = _responses[2];

            data.forEach((_album) => {
              if (_album.year === currentYear) {
                _albumsByYearArray.push(_album);
              }
            });

            setAlbums(_albumsByYearArray);
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const toggleViewAllSpeakers = useCallback(() => {
    setIsSpeakersShownAll((value) => !value);
  }, []);

  const speakersSectionData = useMemo(() => {
    if (isSpeakersShownAll) {
      return speakers;
    }
    if (isDownXs) {
      return speakers.slice(0, 3);
    }
    if (isDownSm) {
      return speakers.slice(0, 6);
    }
    if (isDownMd) {
      return speakers.slice(0, 9);
    }
    if (isDownLg) {
      return speakers.slice(0, 12);
    }
    return speakers.slice(0, 12);
  }, [isSpeakersShownAll, speakers, isDownXs, isDownSm, isDownMd, isDownLg]);

  useEffect(() => {
    const _agendaDays = Object.keys(pageData['agenda'][agendaMonth]);
    setAgendaDays(_agendaDays);
    setAgendaLoaded(true);

    setAgendaIndex(_agendaDays[0]);
    // eslint-disable-next-line
  }, []);

  const handleAgenda = (agendaIndex) => {
    setAgendaIndex(agendaIndex);
  };

  useEffect(() => {
    resetPageData();
  }, [resetPageData]);

  return (
    <Layout>
      <SEO lang='en' title='#GMIS2017' />
      <Grid container direction='column'>
        {isCarouselLoaded ? (
          <Grid item>
            <CarouselStrip theVideos={carousels} oldEvent={true} title={pageData['carouselTitle']} />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Grid xs={12} item>
            <SectionDescription
              left={true}
              image={false}
              ctaLinkExternal
              videoThumbNail='gmis2017-wrap-up'
              url='https://videos.gmisummit.com/hls/gmis-2017-wrap-up/stream.m3u8'
              ctaText='Download the #GMIS2017 Outcomes Report'
              ctaLink='/pdfs/outcomes-book-gmis-2017.pdf'
              title='GMIS2017 ABU DHABI'
              paragraphText='The first edition of the Global Manufacturing and Industrialisation Summit was held under the patronage of His Highness Sheikh Mohamed bin Zayed Al Nahyan, Crown Prince of Abu Dhabi and Deputy Supreme Commander of the UAE Armed Forces, at the Paris Sorbonne University Abu Dhabi in March 2017. #GMIS2017 left a lasting impression on all participants through global partnerships and innovations, bringing together over 3,000 leaders from governments, businesses and civil society to advance manufacturing and industrial development globally and to identify trends and opportunities in technology and innovation; global value chains; skills, employment and education; sustainability and the environment; infrastructure; standards and stakeholder alignment.'
            />
          </Grid>
          <SectionContainerLayoutWithFilter title={pageData['mainSection'].title}>
            {/* <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
              <Box mb={3}>
                  <Typography variant='body1' color='textSecondary'>
                    {pageData['mainSection'].text}
                    </Typography>
                </Box>
                <CustomLink
                type={8}
                  external
                  linkText={pageData['mainSection'].ctaText}
                  linkUrl={`${process.env.GATSBY_HOME_URL}pdfs/${pageData['mainSection'].ctaUrl}`}
                  />
              </Grid>
              <Grid item xs={12} lg={6}>
                <ImageContainer filename={pageData['mainSection'].filename} />
                </Grid>
              </Grid> */}

            <Box my={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <ImageContainer filename={pageData['secondMainSection'].filename} altText={'#GMIS2021'} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography paragraph color='textSecondary'>
                    {pageData['secondMainSection'].text}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box my={5}>
              <Grid container spacing={3} justify='center'>
                {pageData['featuredStatistics'].map((_data, index) => (
                  <Grid item key={index} xs={12} lg={2}>
                    <FeaturedCardStatistic data={_data} isData />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box my={3}>
              <Grid container spacing={3}>
                {pageData['subSection'].map((_data, index) => (
                  <Grid item xs={12} lg={6} key={index}>
                    <StaticImageTitleDescription
                      title={_data.title}
                      description={_data.text}
                      ctaText={_data.ctaText}
                      ctaLink={_data.ctaUrl}
                      lines={6}
                      filename={_data.filename}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </SectionContainerLayoutWithFilter>
        </Grid>
        {/* <Grid item xs={12}>
          {agendaLoaded ? (
            <SectionContainerLayoutWithFilter
              title='AGENDA'
              // filters={agendaDays}
              // viewAllText={agendaMonth}
              // isViewAll
              isDivider
              // callbackFn={handleAgenda}
            >
              <AccordionDataListNew
                year={currentYear}
                day={agendaIndex}
                month={agendaMonth}
                isAgenda={true}
                data={pageData['agenda'][agendaMonth][agendaIndex].data}
              />
            </SectionContainerLayoutWithFilter>
          ) : (
            <SectionContainerLayout title='AGENDA'>
              <Typography variant='h6' color='textSecondary'>
                Loading...
              </Typography>
            </SectionContainerLayout>
          )}
        </Grid> */}

        <Grid item xs={12}>
          {_partner2017 && _partner2017.length > 0 && isShowPartners && (
            <SectionContainerLayoutWithFilter
              title='GMIS2017 PARTNERS'
              isViewAll
              viewAllText='view all'
              baseLink='/partners'>
              <ScrollbarAlwaysOn width='100%' height='100%' showArrows>
                <Box display='flex'>
                  {_partner2017.map((_category) => {
                    if (_category) {
                      return (
                        _category.partner &&
                        _category.partner.length > 0 && (
                          <>
                            <Box key={_category.code} width={150} mx={1} mb={2}>
                              <img
                                style={{
                                  border: '2px solid #a4c627',
                                }}
                                width='150px'
                                src={
                                  _category.file &&
                                  _category.file.code &&
                                  `${process.env.GATSBY_CMS_API_URL}/static/${_category.file.code}`
                                }
                                alt='category'
                              />
                            </Box>
                            {_category &&
                              _category.partner &&
                              _category.partner.length > 0 &&
                              _category.partner.map((_partner) => {
                                return (
                                  <Box key={_partner.code} width={150} mx={1} mb={2}>
                                    <img
                                      width='150px'
                                      src={
                                        _partner.file &&
                                        _partner.file.code &&
                                        `${process.env.GATSBY_CMS_API_URL}/static/${_partner.file.code}`
                                      }
                                      alt='category'
                                    />
                                  </Box>
                                );
                              })}
                          </>
                        )
                      );
                    } else {
                      _partnersCount++;
                      if (_partnersCount === _partner2017.length) {
                        setIsShowPartners(false);
                      }
                    }
                  })}
                </Box>
              </ScrollbarAlwaysOn>
            </SectionContainerLayoutWithFilter>
          )}
        </Grid>

        <Grid item xs={12}>
          <SectionContainerLayoutWithFilter
            title='GMIS2017 SPEAKERS'
            isViewAll
            viewAllText='View All'
            baseLink='/speakers/2017'>
            <>
              <CustomGrid>
                {speakersSectionData.map((speaker, i) => (
                  <CustomGridItem key={`${speaker.code}${i}`}>
                    <AnimatedCard
                      bio={speaker.bio ? speaker.bio : null}
                      image={speaker.file.code}
                      title={
                        speaker.salutationId === 7 || speaker.salutation.name === 'NULL'
                          ? speaker.firstName + ' ' + speaker.lastName
                          : speaker.salutation.name + ' ' + speaker.firstName + ' ' + speaker.lastName
                      }
                      body={
                        speaker.organization && speaker.organization.length > 1
                          ? speaker.designation + ', ' + speaker.organization
                          : speaker.designation
                      }
                    />
                  </CustomGridItem>
                ))}
              </CustomGrid>
              {!isSpeakersShownAll && speakers.length > speakersSectionData.length && (
                <Box mt={3}>
                  <Button fullWidth variant='contained' color='primary' onClick={toggleViewAllSpeakers}>
                    VIEW MORE SPEAKERS
                  </Button>
                </Box>
              )}
            </>
          </SectionContainerLayoutWithFilter>
        </Grid>

        <Grid item>
          <SectionContainerLayoutWithFilter
            title='GMIS2017 GALLERY'
            isViewAll
            viewAllText='View All'
            baseLink='/galleries'>
            <Grid container spacing={3}>
              {albums ? (
                albums.map((_album, index) => (
                  <Grid key={index} item xs={12} sm={6} md={3}>
                    <Box mb={12} width='100%'>
                      <CardImageTitleDescription
                        isImgTag
                        image={_album.files.find((_file) => _file.type !== 'video/mp4').code}
                        title={moment(_album.date).format('DD MMM YYYY')}
                        body={_album.title}
                        ctaText={_album.albumType === 'videos' ? 'View Video Library' : 'View Album'}
                        readMore={`/galleries/${_album.year}/${_album.urlSlug}`}
                      />
                    </Box>
                  </Grid>
                ))
              ) : (
                <p>Loading</p>
              )}
            </Grid>
          </SectionContainerLayoutWithFilter>
        </Grid>

        <Grid item xs={12}>
          {newsData.length > 0 ? (
            <Grid item xs={12}>
              <SectionContainerLayoutWithFilter title='GMIS2017 NEWS' isViewAll viewAllText='View all' baseLink='/news'>
                <SectionContentFeaturedList
                  primaryMediaType='image'
                  secondaryMediaType='image'
                  apiData
                  isNews
                  linkText='Read more'
                  data={newsData}
                />
              </SectionContainerLayoutWithFilter>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Layout>
  );
};

const YEAR = 2017;
export default Page_2017;
